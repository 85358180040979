



























































































import { Component, Prop, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import ReportsProvider from '@/providers/reports.provider';
import Toaster from '../../mixins/toaster.mixin';
import { COURSE_TYPE } from '../../enums';
import { Lang } from '../../types/general.type';
import { ElectronicExamLinkResponse, ElectronicExamsReportResponse } from '../../types/course.type';
import CoursesProvider from '../../providers/courses.provider';

@Component
export default class CourseElectronicExams extends mixins(Toaster) {
  @Prop() readonly courseType!: string;

  @Prop() readonly appId!: string;

  @Prop() readonly callReport!: boolean;

  @Prop(String) courseId!: string;

  @Prop() readonly courseName!: {};

  @Prop(String) creatorId!: string;

  @Prop() readonly creatorName!: {};

  loading = false;

  panelState: number[] = [];

  error = '';

  response: ElectronicExamsReportResponse | null = null;

  headerClass = 'grey darken-4 white--text pa-3';

  get lang() {
    return this.$store.state.lang.lang;
  }

  get tableColumns() {
    const columns = [
      {
        text: this.$t('labels.examTitle'),
        value: 'examTitle',
        sortable: false,
        align: 'center',
        class: this.headerClass,
      },
      {
        text: this.$t('labels.ExamType'),
        value: 'examTypeName',
        sortable: false,
        align: 'center',
        class: this.headerClass,
      },
      {
        text: this.$t('tableHeaders.semester'),
        value: 'semesterName',
        sortable: false,
        align: 'center',
        class: this.headerClass,
      },
      {
        text: this.$t('labels.examDate'),
        sortable: false,
        value: 'examDate',
        align: 'center',
        class: this.headerClass,
      },
      {
        text: this.$t('labels.examDuration'),
        sortable: false,
        value: 'totalTime',
        align: 'center',
        class: this.headerClass,
      },
      {
        text: this.$t('labels.examTotalStudents'),
        sortable: false,
        value: 'totalStudents',
        align: 'center',
        class: this.headerClass,
      },
      {
        text: this.$t('labels.examAttendedStudents'),
        sortable: false,
        value: 'attendedStudents',
        align: 'center',
        class: this.headerClass,
      },
    ];
    if (this.courseType && this.courseType === COURSE_TYPE.PGS_ACADEMIC) {
      columns.splice(3, 0, {
        text: this.$t('tableHeaders.turn'),
        sortable: false,
        value: 'turnName',
        align: 'center',
        class: this.headerClass,
      });
    }
    return columns;
  }

  created() {
    // Listen for the 'open-all-panels' event and open all panels when it's emitted

    this.$on('open-all-panels', () => {
      if (this.response && this.response.years) {
        this.panelState = this.response.years.map((year, index) => index);
      }
    });
  }

  async mounted() {
    this.getElectronicExams();
  }

  @Watch('callReport')
  async callElectronicExams() {
    if (this.callReport) {
      await this.getElectronicExams();
    }
  }

  async getElectronicExams() {
    try {
      this.$emit('reset-call-report');
      this.panelState = [];
      if (this.courseId) {
        this.loading = true;
        let result: ElectronicExamsReportResponse = { years: [] };

        if (this.callReport === true || this.callReport === false) {
          result = await ReportsProvider.getExamsCreatorsReport(
            this.appId, { courseId: this.courseId, creatorId: this.creatorId },
          );
        } else {
          result = await CoursesProvider.getCourseElectronicExams(
            this.appId,
            this.courseId,
          );
        }

        if (result && result.years && result.years.length > 0) {
          this.$emit('showPrint', true);
        } else {
          this.$emit('showPrint', false);
        }
        // Sort years by decending order
        result.years = result.years.sort((a, b) => {
          if (a.id > b.id) {
            return -1;
          }
          if (a.id < b.id) {
            return 1;
          }
          return 0;
        });
        // Sorts exam in each year by examDate decending order
        result.years = result.years.map((year) => ({
          ...year,
          exams: year.exams.sort((a, b) => {
            if (a.examDate > b.examDate) {
              return -1;
            }
            if (a.examDate < b.examDate) {
              return 1;
            }
            return 0;
          }),
        }));
        this.response = result;
        this.loading = false;
      } else {
        this.$emit('showPrint', false);
      }
    } catch (err) {
      this.loading = false;
      this.error = err;
    }
  }

  async requestRedirect(value) {
    try {
      const result: ElectronicExamLinkResponse = await CoursesProvider.getExamRedirect(
        this.appId,
        this.courseId,
        value.examId,
      );
      const newTab = window.open(result.url, '_blank');
      if (newTab) {
        newTab.focus();
      }
    } catch (err) {
      this.errorToaster(err as Lang, { duration: 5000 });
    }
  }
}
